import AutoResponseAPI from '../api/auto_response_api';
import FolderAPI from '../api/folder_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  tags: [],
  folders: [],
  autoResponses: [],
  total: 1,
  message: null,
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  queryParams: {
    name_cont: null,
    page: 1,
    folder_id_eq: ''
  }
};

export const mutations = {
  setAutoResponses(state, auto_responses) {
    state.autoResponses = auto_responses;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  pushFolder(state, folder) {
    folder.auto_responses = [];
    state.folders.push(folder);
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  updateFolder(state, params) {
    const item = state.folders.find(item => item.id === params.id);
    if (item) {
      item.name = params.name;
    }
  },

  deleteFolder(state, id) {
    state.folders = state.folders.filter(item => item.id !== id);
  },

  setQueryParams(state, param) {
    Object.assign(state.queryParams, param);
  },

  updateFolderOrder(state, { oldIndex, newIndex }) {
    const movedFolder = state.folders.splice(oldIndex, 1)[0];
    state.folders.splice(newIndex, 0, movedFolder);
  },

  updateAutoResponseOrder(state, { oldIndex, newIndex }) {
    const movedAutoResponse = state.autoResponses.splice(oldIndex, 1)[0];
    state.autoResponses.splice(newIndex, 0, movedAutoResponse);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {

  setPreviewContent(context, autoResponse) {
    context.dispatch('preview/setMessages', autoResponse.messages, { root: true });
  },

  async getAutoResponses(context, query) {
    let autoResponses = null;
    const params = {
      page: state.queryParams.page,
      q: _.omit(state.queryParams, 'page')
    };
    const combinedQuery = { ...query, ...params };
    try {
      const res = await AutoResponseAPI.list(combinedQuery);
      autoResponses = res;
    } catch (error) {
      console.log(error);
    }
    context.commit('setAutoResponses', autoResponses.data);
    context.commit('setMeta', autoResponses.meta);
  },

  async getAutoResponse(context, id) {
    return await AutoResponseAPI.get(id);
  },

  async createAutoResponse(context, autoResponseData) {
    try {
      return await AutoResponseAPI.create(autoResponseData);
    } catch (error) {
      return null;
    }
  },

  async updateAutoResponse(context, autoResponse) {
    try {
      return await AutoResponseAPI.update(autoResponse);
    } catch (error) {
      return null;
    }
  },

  async copyAutoResponse(context, id) {
    try {
      return await AutoResponseAPI.copy(id);
    } catch (error) {
      return null;
    }
  },

  async deleteAutoResponse(context, id) {
    try {
      return await AutoResponseAPI.delete(id);
    } catch (error) {
      return null;
    }
  },

  async getFolders(context) {
    try {
      const params = { type: 'auto_response', name: state.queryParams.name_cont };
      const response = await FolderAPI.list(params);
      context.commit('setFolders', response);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async createFolder(context, payload) {
    try {
      const res = await FolderAPI.create(payload);
      context.commit('pushFolder', res);
      return res;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const res = await FolderAPI.update(payload);
      context.commit('updateFolder', payload);
      return res;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async reorderFolders(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FolderAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to swap folder positions:', error);
    }
  },

  async reorderAutoResponses(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await AutoResponseAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder auto responses:', error);
    }
  }
};
