<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body mvh-50">
          <div class="d-flex">
            <reorder-folder-left
              type="scenario"
              :data="folders"
              :isPc="isPc"
              :selectedFolder="selectedFolderIndex"
              @changeSelectedFolder="onSelectedFolderChanged"
              @submitUpdateFolder="submitUpdateFolder"
              @submitCreateFolder="submitCreateFolder"
              @reorder="onDragFolderEnd"
            />
            <div class="content" :key="contentKey">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <a
                  v-if="folders && folders.length && curFolder"
                  :href="`${rootPath}/user/scenarios/new?folder_id=${curFolder.id}`"
                  class="btn btn-primary"
                  >
                    <i class="uil-plus"></i> 新規作成
                  </a>
                </div>
                <div>
                  <select class="form-control fw-150 mr-1" v-model="queryParams.status_eq" @change="search()">
                    <option value="">すべて</option>
                    <option value="enabled">稼働中</option>
                    <option value="disabled">停止中</option>
                    <option value="draft">下書き</option>
                  </select>
                </div>
              </div>
              <div class="mt-2" v-if="curFolder">
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th></th>
                        <th class="mw-120">配信方式</th>
                        <th>シナリオ名</th>
                        <th colspan="2" class="text-center mw-230">統計</th>
                        <th class="mw-120">状況</th>
                        <th class="mw-200">メッセージ</th>
                        <th>操作</th>
                        <th></th>
                      </tr>
                    </thead>
                    <draggable
                      tag="tbody"
                      v-model="scenariosCopy"
                      @end="onDragScenarioEnd"
                      drag-class="dragging-item"
                      ghost-class="destination-item"
                    >
                      <tr v-for="(scenario, index) in scenariosCopy" :key="index">
                        <td class="drag-icon-cell">
                          <i class="mdi mdi-drag text-secondary"></i>
                        </td>
                        <td>{{ scenario.mode === "elapsed_time" ? "経過時間" : "時刻" }}</td>
                        <td class="fw-300 text-truncate">{{ scenario.title }}</td>
                        <td class="text-center">
                          {{ scenario.sending_friend_count }}人 <span class="font-13">(購読中)</span>
                        </td>
                        <td class="text-center">
                          {{ scenario.sent_friend_count }}人 <span class="font-13">(購読済み)</span>
                        </td>
                        <td><scenario-status :status="scenario.status"></scenario-status></td>
                        <td>
                          <div class="btn btn-light" @click="openMessageIndex(scenario)">
                            メッセージ一覧（{{ scenario.scenario_messages_count || 0 }}）
                          </div>
                        </td>
                        <td>
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-light btn-sm dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              操作 <span class="caret"></span>
                            </button>
                            <div class="dropdown-menu">
                              <a role="button" class="dropdown-item" :href="`${rootPath}/user/scenarios/${scenario.id}/edit`"
                                >シナリオを編集する</a
                              >
                              <a
                                role="button"
                                class="dropdown-item"
                                data-toggle="modal"
                                data-target="#modalCopyScenario"
                                @click="curScenarioIndex = index"
                                >シナリオをコピー</a
                              >
                              <a
                                role="button"
                                class="dropdown-item"
                                data-toggle="modal"
                                data-target="#modalSendScenarioToTesters"
                                @click="curScenarioIndex = index"
                                >テスト配信</a
                              >
                              <a
                                role="button"
                                class="dropdown-item"
                                data-toggle="modal"
                                data-target="#modalDeleteScenario"
                                @click="curScenarioIndex = index"
                                >シナリオを削除</a
                              >
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="actions ml-auto mr-2">
                            <button
                              @click.stop="moveScenario(index, 'up')"
                              :class="{ disabled: index === 0 && queryParams.page === 1 }"
                              class="btn btn-light btn-sm action-button"
                              :disabled="index === 0 && queryParams.page === 1"
                            >
                              <i class="dripicons-chevron-up"></i>
                            </button>
                            <button
                              @click.stop="moveScenario(index, 'down')"
                              :class="{ disabled: index === scenariosCopy.length - 1 && queryParams.page === totalPages }"
                              class="btn btn-light btn-sm action-button"
                              :disabled="index === scenariosCopy.length - 1 && queryParams.page === totalPages"
                            >
                              <i class="dripicons-chevron-down"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
                <div class="d-flex justify-content-center mt-3 text-center">
                  <b-pagination
                    v-if="totalRows > perPage"
                    v-model="queryParams.page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="loadPage"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
                <div class="text-center my-5 font-weight-bold" v-if="!loading && totalRows === 0">シナリオはありません。</div>
              </div>
            </div>
          </div>
        </div>

        <loading-indicator :loading="loading"></loading-indicator>
      </div>
    </div>

    <!-- START: Delete folder modal -->
    <modal-confirm
      title="このフォルダーを削除してもよろしいですか？"
      id="modalDeleteFolder"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="curFolder">
        <span>フォルダー名：{{ curFolder.name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <!-- START: Delete scenario modal -->
    <modal-confirm
      title="このシナリオを削除してもよろしいですか？"
      id="modalDeleteScenario"
      type="delete"
      @confirm="submitDeleteScenario"
    >
      <template v-slot:content>
        <p v-if="curScenario">
          シナリオ名：<b>{{ curScenario.title }}</b
          ><br />
        </p>
        <div class="alert alert-warning">こちらのシナリオの配信予約したメッセージは停止されます。</div>
      </template>
    </modal-confirm>
    <!-- END: Delete scenario modal -->

    <!-- START: Copy scenario modal -->
    <modal-confirm
      title="このシナリオをコピーしてもよろしいですか？"
      id="modalCopyScenario"
      type="confirm"
      @confirm="submitCopyScenario"
    >
      <template v-slot:content>
        <div v-if="curScenario">
          シナリオ名：<b>{{ curScenario.title }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Copy scenario modal -->
    <!-- START: send scenario to testers modal -->
    <modal-confirm
      title="シナリオのテスト配信のため、テスターを選択してください。"
      id="modalSendScenarioToTesters"
      type="confirm"
      @confirm="submitSendScenarioToTesters"
      :confirmButtonDisabled="selectedTesterIds.length === 0"
      confirmButtonLabel="テスト配信"
    >
      <template v-slot:content>
        <div v-if="curScenario">
          <div v-if="testers && testers.length" class="d-flex">
            <div
              class="flex-1 custom-control custom-checkbox mr-2"
              v-for="tester in testers"
              :key="`tester_${tester.id}`"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="`tester_${tester.id}`"
                v-model="selectedTesterIds"
                :value="tester.id"
              />
              <label class="custom-control-label" :for="`tester_${tester.id}`">{{ tester.display_name }}</label>
            </div>
          </div>
          <div v-else>
            <span>テスターはありません。</span>
          </div>
        </div>
      </template>
    </modal-confirm>
    <!-- END: send scenario to testers modal -->
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      loading: true,
      contentKey: 0,
      currentPage: 1,
      queryParams: null,
      curScenarioIndex: 0,
      selectedTesterIds: [],
      selectedFolderIndex: 0,
      scenariosCopy: []
    };
  },

  props: ['testers'],

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders();
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.onSelectedFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('scenario', ['getQueryParams']),
    ...mapState('scenario', {
      folders: state => state.folders,
      scenarios: state => state.scenarios,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },

    curScenario() {
      return this.scenarios[this.curScenarioIndex];
    },

    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    }
  },

  methods: {
    ...mapMutations('scenario', ['setQueryParams', 'updateFolderOrder', 'updateScenarioOrder']),
    ...mapActions('scenario', [
      'getScenarios',
      'copyScenario',
      'deleteScenario',
      'sendScenarioToTesters',
      'getFolders',
      'deleteFolder',
      'createFolder',
      'updateFolder',
      'reorderFolders',
      'reorderScenarios'
    ]),

    async onDragFolderEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.folders,
        this.reorderFolders,
        this.updateFolderOrder
      );
    },

    async moveScenario(index, direction) {
      this.queryParams.page = await Util.moveItem(
        this.scenariosCopy,
        index,
        direction,
        this.reorderScenarios.bind(this),
        this.updateScenarioOrder.bind(this),
        null,
        this.queryParams.page,
        this.totalPages
      );

      await this.getScenarios();
    },

    async onDragScenarioEnd(event) {
      await Util.handleDragAndDrop(
        event,
        this.scenariosCopy,
        this.reorderScenarios.bind(this),
        this.updateScenarioOrder.bind(this)
      );

      await this.getScenarios;
    },

    forceRerender() {
      this.contentKey++;
    },

    onSelectedFolderChanged(index) {
      this.queryParams.page = 1;
      this.selectedFolderIndex = index;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async submitCreateFolder(folder) {
      const response = await this.createFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.curFolder.id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onSelectedFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    search() {
      this.queryParams.page = 1;
      this.loadPage();
    },

    async loadPage() {
      // bootstrap pagination return old value of current page,
      // using nextTick to solve the issue
      this.$nextTick(async() => {
        this.loading = true;
        this.setQueryParams(this.queryParams);
        await this.getScenarios();
        this.scenariosCopy = this.scenarios;
        this.forceRerender();
        this.loading = false;
      });
    },

    openEdit(scenario) {
      window.open(`${process.env.MIX_ROOT_PATH}/user/scenarios/${scenario.id}/edit`);
    },

    openMessageIndex(scenario) {
      location.href = `${process.env.MIX_ROOT_PATH}/user/scenarios/${scenario.id}/messages`;
    },

    async submitDeleteScenario() {
      const response = await this.deleteScenario(this.curScenario.id);
      if (response) {
        Util.showSuccessThenRedirect('シナリオの削除は完了しました。', location.href);
      } else {
        window.toastr.error('シナリオの削除は失敗しました。');
      }
      this.forceRerender();
    },

    async submitCopyScenario() {
      const response = await this.copyScenario(this.curScenario.id);
      if (response) {
        Util.showSuccessThenRedirect('シナリオのコピーは完了しました。', location.href);
      } else {
        window.toastr.error('シナリオのコピーは失敗しました。');
      }
    },

    async submitSendScenarioToTesters() {
      const response = await this.sendScenarioToTesters({
        scenario_id: this.curScenario.id,
        line_friend_ids: this.selectedTesterIds
      });
      if (response) {
        Util.showSuccessThenRedirect('シナリオのテスト配信は完了しました。', location.href);
      } else {
        window.toastr.error('シナリオのテスト配信は失敗しました。');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .content {
    width: calc(100% - 300px);
  }
  .dragging-item {
    opacity: 0.8;
    border: 2px solid #008a00 !important;
  }
  .destination-item {
    opacity: 0.5;
    border: 2px dashed #6a0dad;
    background-color: #d8bfd8;
  }
</style>
