import RichMenuAPI from '../api/richmenu_api';
import FolderAPI from '../api/folder_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  folders: [],
  richMenus: [],
  totalRows: 0,
  perPage: 1,
  curPage: 1,
  queryParams: {
    page: 1,
    folder_id_eq: ''
  }
};

export const mutations = {
  pushFolder(state, folder) {
    state.folders.push(folder);
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  updateFolder(state, folder) {
    const item = state.folders.find(item => item.id === folder.id);
    if (item) {
      item.name = folder.name;
    }
  },

  deleteFolder(state, id) {
    const index = state.folders.findIndex(_ => _.id === id);
    state.folders.splice(index, 1);
  },

  setRichMenus(state, rich_menus) {
    state.richMenus = rich_menus;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setQueryParams(state, param) {
    Object.assign(state.queryParams, param);
  },

  updateFolderOrder(state, { oldIndex, newIndex }) {
    const movedFolder = state.folders.splice(oldIndex, 1)[0];
    state.folders.splice(newIndex, 0, movedFolder);
  },

  updateRichMenuOrder(state, { oldIndex, newIndex }) {
    const movedRichMenu = state.richMenus.splice(oldIndex, 1)[0];
    state.richMenus.splice(newIndex, 0, movedRichMenu);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getRichMenus(context, query) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const combinedQuery = { ...query, ...params };
      const res = await RichMenuAPI.list(combinedQuery);

      context.commit('setRichMenus', res.data);
      context.commit('setMeta', res.meta);
    } catch (error) {
      return null;
    }
  },

  async createFolder(context, payload) {
    try {
      const folder = await FolderAPI.create(payload);
      folder.rich_menus = [];
      context.commit('pushFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const folder = await FolderAPI.update(payload);
      folder.rich_menus = [];
      context.commit('updateFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async getRichMenu(_, id) {
    try {
      return await RichMenuAPI.get(id);
    } catch (error) {
      return null;
    }
  },

  async createRichMenu(_, payload) {
    try {
      return await RichMenuAPI.create(payload);
    } catch (error) {
      return null;
    }
  },

  async updateRichMenu(_, payload) {
    try {
      return await RichMenuAPI.update(payload);
    } catch (error) {
      return null;
    }
  },

  async copyRichMenu(_, id) {
    try {
      return await RichMenuAPI.copy(id);
    } catch (error) {
      return null;
    }
  },

  async deleteRichMenu(_, id) {
    try {
      return await RichMenuAPI.delete(id);
    } catch (error) {
      return null;
    }
  },

  async getFolders(context) {
    try {
      const params = {
        type: 'rich_menu'
      };
      const response = await FolderAPI.list(params);
      context.commit('setFolders', response);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async reorderFolders(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FolderAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to swap folder positions:', error);
    }
  },

  async reorderRichMenus(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await RichMenuAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder richMenus:', error);
    }
  }
};
