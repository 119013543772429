export default {
  list: (query) => {
    return window.$.ajax({
      url: process.env.MIX_ROOT_PATH + '/user/marks',
      method: 'GET',
      dataType: 'json',
      data: query,
      contentType: 'application/json',
      cache: true
    });
  },

  update: (query) => {
    return window.$.ajax({
      url: `${process.env.MIX_ROOT_PATH}/user/marks/update_multiple`,
      method: 'PATCH',
      data: JSON.stringify(query),
      contentType: 'application/json'
    });
  },

  delete: (id) => {
    return window.$.ajax({
      url: process.env.MIX_ROOT_PATH + '/user/marks/' + id,
      method: 'DELETE'
    });
  }
};
