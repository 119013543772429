<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-grow-1 folder-right">
          <div class="d-flex">
            <div class="btn btn-primary" @click="addMark"><i class="uil-plus"></i> 新しい選択肢を追加</div>
            <div class="ml-auto d-flex">
              <div class="input-group app-search">
                <input
                    type="text"
                    class="form-control dropdown-toggle fw-250"
                    placeholder="検索..."
                    v-model="queryParams.name_cont"
                />
                <span class="mdi mdi-magnify search-icon"></span>
                <div class="input-group-append">
                  <div class="btn btn-primary" @click="search()">検索</div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table mb-0">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>選扒肢</th>
                  <th>カラー</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                v-model="marks"
                @end="swapPositions"
                drag-class="dragging-item"
                ghost-class="destination-item"
                class="mark-body"
              >
                <tr v-for="(item, index) in marks" :key="index">
                  <td class="w-90 drag-icon-cell">
                    <div class="h-38 w-60 place-center">
                      <i class="mdi mdi-drag text-secondary"></i>
                    </div>
                  </td>
                  <td>
                    <div class="input-group newgroup-inputs">
                      <input
                        type="text"
                        placeholder="マークネーム"
                        class="form-control"
                        v-model.trim="item.name"
                        :name="'mark_name_' + item.index"
                        data-vv-as="マークネーム"
                        maxlength="33"
                        v-validate="'required|max:32'"
                      />
                    </div>
                    <error-message :message="errors.first('mark_name_' + item.index)"></error-message>
                  </td>
                  <td class="colour-picker w-110 text-center" :id="'colour-picker-' + index">
                    <colour-picker v-model="item.color" :color="item.color" :no-input="true" picker="compact" />
                  </td>
                  <td class="w-40 text-right">
                    <button class="btn btn-light" @click="deleteMark(item.id, index)" type="button">
                      <span class="font-weight-bold h5" aria-hidden="true">×</span>
                    </button>
                  </td>
                  <td class="w-130">
                    <div class="actions ml-auto">
                      <button
                        @click.stop="moveMarkIndex(index, 'up')"
                        :class="{ disabled: index === 0 }"
                        class="btn btn-light action-button"
                        :disabled="index === 0"
                      >
                        <i class="dripicons-chevron-up"></i>
                      </button>
                      <button
                        @click.stop="moveMarkIndex(index, 'down')"
                        :class="{ disabled: index === marks.length - 1 }"
                        class="btn btn-light action-button"
                        :disabled="(index === marks.length - 1)"
                      >
                        <i class="dripicons-chevron-down"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div v-if="marks.length === 0" class="my-5 text-md text-center">
              <b>データなし</b>
            </div>

            <div v-if="!hideAction" class="d-flex px-2 mt-3">
              <div class="input-group-btn">
                <button @click="clearData" type="button" class="btn btn-warning text-white">キャンセル</button>
              </div>
              <div class="input-group-btn ml-auto">
                <button @click="submitUpdateMarks" type="button" class="btn btn-success">保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ColourPicker from 'vue-colour-picker';
import Util from '@/core/util';

export default {
  data() {
    return {
      loading: true,
      hideAction: true,
      marks: [],
      destroyIds: [],
      queryParams: {}
    };
  },

  computed: {
    ...mapState('mark', {
      meta: state => state.meta
    })
  },

  async beforeMount() {
    this.loading = true;
    await this.fetchData();
    this.setColor();
    this.loading = false;
  },

  components: {
    'colour-picker': ColourPicker
  },

  watch: {
    marks: {
      handler() {
        setTimeout(() => {
          this.setColor();
        }, 10);
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations('mark', ['setQueryParams', 'increaseTotalCount']),
    ...mapActions('mark', ['getMarks', 'updateMarks']),

    setColor() {
      this.marks.forEach((mark, index) => {
        const parentElement = document.getElementById('colour-picker-' + index);
        if (parentElement) {
          const color = Util.getInversionColor(mark.color);
          parentElement.style.setProperty('--colour-picker-text', color);
        }
      });
    },

    addMark() {
      this.increaseTotalCount();
      this.marks.push({
        id: null,
        index: this.meta.total_count,
        name: null,
        color: null
      });
      this.hideAction = false;
    },

    deleteMark(id, index) {
      this.marks.splice(index, 1);
      this.destroyIds.push(id);
    },

    async submitUpdateMarks() {
      const passed = await this.$validator.validateAll();
      if (!passed) return;

      const response = await this.updateMarks({
        marks: this.marks,
        destroy_ids: this.destroyIds
      });
      this.fetchData();
      if (response) {
        window.toastr.success('マークの更新が成功しました');
      } else {
        window.toastr.error('マークの更新に失敗しました');
      }
    },

    async fetchData() {
      this.marks = await this.getMarks();
      this.hideAction = this.marks.length === 0;
    },

    async search() {
      this.loading = true;
      this.setQueryParams(this.queryParams);
      this.fetchData();
      this.loading = false;
    },

    async clearData() {
      this.destroyIds = [];
      this.fetchData();
    },

    async moveMarkIndex(index, direction) {
      const currentMark = this.marks[index];

      switch (direction) {
      case 'up':
        this.marks[index] = this.marks[--index];
        this.marks[index] = currentMark;
        break;

      case 'down':
        this.marks[index] = this.marks[++index];
        this.marks[index] = currentMark;
      }

      this.swapPositions();
    },

    async swapPositions() {
      this.marks.map((item, idx) => {
        item.index = idx + 1;
        return item;
      });
    }
  }
};
</script>

<style lang="scss">
.mark-body {
  .place-center {
    place-content: center;
  }
  .colour-picker {
    .current-color {
      width: 100px;
      height: 38px;
      padding: 8px;
    }
    .current-color:after {
      content: '設定';
      color: var(--colour-picker-text);
    }
    .vc-compact {
      left: -230px;
      width: 230px !important;
    }
  }
}
</style>
