<template>
    <div>
      <div class="card mvh-50">
        <div class="card-header left-border text-md">
          <b>現在のマーク別人数</b>
          <button @click="fetchData" class="btn btn-light btn-sm float-right">再読み込み</button>
        </div>
        <div class="card-body" v-if="!loading">
          <div
            class="mb-2"
            v-for="mark in marks"
            :key="mark.id"
          >
            <button
                class="btn btn-light btn-sm box-shadow-none"
                :style="{
                    backgroundColor: mark.color,
                    color: getInversionColor(mark.color),
                    inlineSize: 'max-content'
                }"
            >
                {{mark.name | truncate(17)}}
            </button>
            <div class="float-right">
                <span class="mt-2">{{ mark.friends_count }}人</span>
                <button
                    role="button"
                    class="btn btn-light btn-sm d-inline-block"
                    data-toggle="modal"
                    data-target="#modalMarkFriends"
                    @click="markSelected = mark"
                    :disabled="mark.friends_count === 0"
                >
                    表示
                </button>
            </div>
          </div>
          <div v-if="marks.length === 0" class="text-center mt-4"><b>データなし</b></div>
        </div>

        <loading-indicator :loading="loading"></loading-indicator>
      </div>
      <modal-mark-friends :mark="markSelected"></modal-mark-friends>
    </div>
  </template>
<script>
import { mapActions } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      loading: true,
      marks: [],
      markSelected: null
    };
  },

  async beforeMount() {
    this.fetchData();
  },

  methods: {
    ...mapActions('mark', ['getMarks']),

    async fetchData() {
      this.loading = true;
      this.marks = await this.getMarks();
      this.loading = false;
    },

    getInversionColor(color) {
      return Util.getInversionColor(color);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>