<template>
    <label
        v-if="mark"
        class="btn btn-light btn-xs box-shadow-none"
        :style="{
            backgroundColor: mark.color,
            color: getInversionColor(mark.color),
            inlineSize: 'max-content'
        }">
        {{mark.name | truncate(truncateNum)}}
    </label>
    <span v-else>なし</span>
</template>

<script>
import Util from '@/core/util';

export default {
  props: {
    mark: {
      type: Object,
      required: true
    },
    truncateNum: {
      Number,
      default: 7
    }
  },

  methods: {
    getInversionColor(color) {
      return Util.getInversionColor(color);
    }
  }
};
</script>
