import TemplateAPI from '../api/template_api';
import FolderAPI from '../api/folder_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  folders: [],
  templates: [],
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  queryParams: {
    page: 1,
    folder_id_eq: ''
  }
};

export const mutations = {
  pushFolder(state, folder) {
    folder.templates = [];
    state.folders.push(folder);
  },

  setFolders(state, folders) {
    state.folders = folders;
  },

  updateFolder(state, newItem) {
    const item = state.folders.find(item => item.id === newItem.id);
    if (item) {
      item.name = newItem.name;
    }
  },

  deleteFolder(state, id) {
    const index = state.folders.findIndex(_ => _.id === id);
    state.folders.splice(index, 1);
  },

  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setTemplates(state, templates) {
    state.templates = templates;
  },

  updateFolderOrder(state, { oldIndex, newIndex }) {
    const movedFolder = state.folders.splice(oldIndex, 1)[0];
    state.folders.splice(newIndex, 0, movedFolder);
  },

  updateTemplateOrder(state, { oldIndex, newIndex }) {
    const movedTemplate = state.templates.splice(oldIndex, 1)[0];
    state.templates.splice(newIndex, 0, movedTemplate);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  setMessagePreview(context, template) {
    context.dispatch('preview/setMessages', template.messages, { root: true });
  },

  async getFolders(context, query) {
    try {
      const params = { type: 'template' };
      const combinedQuery = { ...query, ...params };
      const response = await FolderAPI.list(combinedQuery);
      context.commit('setFolders', response);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getTemplates(context, query) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const combinedQuery = { ...query, ...params };
      const response = await TemplateAPI.list(combinedQuery);

      context.commit('setTemplates', response.data);
      context.commit('setMeta', response.meta);
    } catch (error) {
      console.log(error);
    }
  },

  async getTemplate(_, id) {
    try {
      return await TemplateAPI.get(id);
    } catch (error) {
      return null;
    }
  },

  async updateTemplate(context, query) {
    try {
      const response = await TemplateAPI.update(query);
      return response;
    } catch (error) {
      return null;
    }
  },

  async createTemplate(context, payload) {
    try {
      const response = await TemplateAPI.create(payload);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteTemplate(context, id) {
    try {
      return await TemplateAPI.delete(id);
    } catch (error) {
      return null;
    }
  },

  async copyTemplate(context, id) {
    try {
      return await TemplateAPI.copy(id);
    } catch (error) {
      return null;
    }
  },

  async createFolder(context, payload) {
    try {
      const folder = await FolderAPI.create(payload);
      context.commit('pushFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const response = await FolderAPI.update(payload);
      context.commit('updateFolder', response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async reorderFolders(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FolderAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to swap folder positions:', error);
    }
  },

  async reorderTemplates(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await TemplateAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder templates:', error);
    }
  }
};
