<template>
  <div class="modal fade" id="modalSetMark" tabindex="-1" role="dialog" aria-labelledby="modalSetMark">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span>&times;</span></button>
          <p class="mb10 fz14">対応マーク変更</p>
          <dl class="flex group-modal01 no-mgn flex-wrap justify-content-between">
            <mark-selection @select="onSelectMark" :selected="null"></mark-selection>
          </dl>
        </div>
        <div class="modal-footer flex center">
          <button type="button" class="btn text-white btn-modal-confirm" data-dismiss="modal" :disabled="!markIdSelected" @click="select">選択する</button>
          <button type="button" class="btn btn-modal-cancel" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      markIdSelected: null
    };
  },

  methods: {
    select() {
      this.$emit('select', this.markIdSelected);
    },

    onSelectMark(mark) {
      this.markIdSelected = mark.id;
    }
  }
};
</script>
