<template>
  <div
    class="modal fade"
    :id="id ? id : 'modalSelectReminder'"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg vh-90 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">リマインダを選択してください</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex" v-if="folders && folders.length">
            <folder-left
              type="reminder"
              :isPerview="true"
              :data="folders"
              :isPc="isPc"
              :selectedFolder="selectedFolder"
              @changeSelectedFolder="changeSelectedReminder"
            />
            <div class="flex-grow-1">
              <table class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>
                      <span v-if="curFolder">{{ curFolder.name }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="reminders && reminders.length">
                  <tr v-for="(item, index) in reminders" :key="index" class="folder-item">
                    <td class="d-flex w-100">
                      <div class="mr-1">{{ item.name }}</div>
                      <div
                        class="btn btn-info btn-sm ml-auto my-auto fw-80"
                        data-dismiss="modal"
                        @click="selectReminder(item)"
                      >
                        選択
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center my-5">データーがありません</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center mt-3 text-center">
                <b-pagination
                    v-if="totalRows > perPage"
                    v-model="queryParams.page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="loadPage"
                    aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
export default {
  props: ['id'],

  data() {
    return {
      selectedFolder: 0,
      isPc: true,
      queryParams: {
        page: 1,
        folder_id_eq: ''
      }
    };
  },

  computed: {
    ...mapState('reminder', {
      folders: state => state.folders,
      reminders: state => state.reminders,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolder];
    }
  },

  async beforeMount() {
    await this.getFolders();
    this.changeSelectedReminder(0);
  },

  methods: {
    ...mapMutations('reminder', ['setQueryParams']),
    ...mapActions('reminder', ['getFolders', 'getReminders']),

    backToFolder() {
      this.isPc = false;
    },

    selectReminder(reminder) {
      const data = _.cloneDeep(reminder);
      this.$emit('selectReminder', data);
    },

    changeSelectedReminder(index) {
      this.selectedFolder = index;
      this.isPc = true;
      this.queryParams.folder_id_eq = this.curFolder.id;
      this.loadPage()
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        await this.getReminders();
      });
    },
  }
};
</script>
