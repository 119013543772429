import FriendAPI from '../api/friend_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  friend: null,
  friends: [],
  reminders: [],
  totalRows: 0,
  perPage: 0,
  totalPages: 0,
  queryParams: {
    page: 1,
    status_eq: 'active',
    line_name_or_display_name_cont: null,
    tags_id_in: null,
    created_at_gteq: null,
    created_at_lteq: null,
    visible_eq: true,
    locked_eq: false,
    channel_assignee_id_eq: null
  },
  clearQueryParams: false
};

export const mutations = {
  setFriend(state, friend) {
    state.friend = friend;
  },

  setFriends(state, friends) {
    state.friends = friends;
  },

  setReminders(state, reminders) {
    state.reminders = reminders;
  },

  setReminder(state, reminder) {
    state.reminders.unshift(reminder);
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
    state.totalPages = meta.total_pages;
  },

  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setQueryParam(state, param) {
    Object.assign(state.queryParams, param);
  },

  resetQueryParams(state) {
    state.queryParams = {
      page: 1,
      status_eq: 'active',
      line_name_or_display_name_cont: null,
      tags_id_in: null,
      created_at_gteq: null,
      created_at_lteq: null,
      visible_eq: true,
      locked_eq: false
    };
  },

  resetFriends(state) {
    state.friends = [];
  },

  setClearQueryParams(state, clearQueryParams) {
    state.clearQueryParams = clearQueryParams;
  },

  updateFriendOrder(state, { oldIndex, newIndex }) {
    const movedFriend = state.friends.splice(oldIndex, 1)[0];
    state.friends.splice(newIndex, 0, movedFriend);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getFriends(context) {
    const params = {
      page: state.queryParams.page,
      q: _.omit(state.queryParams, 'page')
    };
    try {
      const response = await FriendAPI.list(params);
      context.commit('setFriends', response.data);
      context.commit('setMeta', response.meta);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async getFriend(context, id) {
    let friend = null;
    try {
      friend = await FriendAPI.get(id);
      context.commit('setFriend', friend);
      return friend;
    } catch (error) {
      console.log(error);
      return error.responseJSON;
    }
  },

  async updateFriend(_, query) {
    try {
      return await FriendAPI.update(query);
    } catch (error) {
      return null;
    }
  },

  async toggleLocked(context, id) {
    try {
      return await FriendAPI.toggleLocked(id);
    } catch (error) {
      return null;
    }
  },

  async toggleVisible(context, id) {
    try {
      return await FriendAPI.toggleVisible(id);
    } catch (error) {
      return null;
    }
  },

  async getReminders(context, id) {
    try {
      const reminders = await FriendAPI.getReminders(id);
      context.commit('setReminders', reminders);
      return reminders;
    } catch (error) {
      return null;
    }
  },

  async setReminder(context, payload) {
    try {
      const reminder = await FriendAPI.setReminder(payload);
      context.commit('setReminder', reminder);
      return reminder;
    } catch (error) {
      return null;
    }
  },

  async setMark(context, payload) {
    try {
      const mark = await FriendAPI.setMark(payload);
      return mark;
    } catch (error) {
      return null;
    }
  },

  async getVariables(context, id) {
    try {
      return await FriendAPI.getVariables(id);
    } catch (error) {
      return null;
    }
  },

  async reorderFriends(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FriendAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder Friends:', error);
    }
  }
};