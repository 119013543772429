<template>
  <div
    class="modal fade"
    :id="id ? id : 'modal-template'"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    ref="modalSelectVariable"
  >
    <div class="modal-dialog modal-lg vh-90 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">友達情報名を選択してください</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body card">
          <div class="d-flex">
            <folder-left
              type="variable"
              :isPerview="true"
              :data="folders"
              :selectedFolder="selectedFolderIndex"
              @changeSelectedFolder="changeSelectedFolder"
            ></folder-left>
            <div class="flex-grow-1 scroll-table" :key="contentKey">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th>名称</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(variable, index) in variables" v-bind:key="index">
                    <td>{{ variable.name }}</td>
                    <td class="fw-120">
                      <div @click="selectVariable(variable)" class="btn btn-sm btn-light" data-dismiss="modal">
                        選択
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center mt-3 text-center">
                <b-pagination
                    v-if="totalRows > perPage"
                    v-model="queryParams.page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="loadPage"
                    aria-controls="my-table"
                ></b-pagination>
              </div>
              <div class="text-center mt-5" v-if="!loading && totalRows === 0">
                <b>友だち情報欄はありません。</b>
              </div>
            </div>
          </div>
          <loading-indicator :loading="loading"></loading-indicator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
export default {
  props: ['id', 'type'],
  data() {
    return {
      contentKey: 0,
      selectedFolderIndex: 0,
      loading: true,
      queryParams: {}
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  computed: {
    ...mapGetters('variable', ['getQueryParams']),
    ...mapState('variable', {
      folders: state => state.folders,
      variables: state => state.variables,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),
    curFolder() {
      return this.folders[this.selectedFolderIndex];
    }
  },

  mounted() {
    $(this.$refs.modalSelectVariable).on('show.bs.modal', this.reloadVariables);
  },

  methods: {
    ...mapMutations('variable', ['setQueryParams']),
    ...mapActions('variable', ['getFolders', 'getVariables']),

    forceRerender() {
      this.contentKey++;
    },

    async reloadVariables() {
      await this.getFolders();
      this.changeSelectedFolder(0);
      this.loading = false;
    },

    changeSelectedFolder(index) {
      this.selectedFolderIndex = index;
      this.queryParams.page = 1;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    selectVariable(variable) {
      const data = _.cloneDeep(variable);
      this.$emit('selectVariable', data);
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        await this.getVariables();
        this.forceRerender();
        this.loading = false;
      });
    }
  }
};
</script>
