<template>
  <div
    class="modal fade"
    id="modalSendTemplate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl vh-90 modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">テンプレートを選択してください</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex" v-if="folders && folders.length">
            <folder-left
              type="template_message"
              :isPerview="true"
              :data="folders"
              :isPc="isPc"
              :selectedFolder="selectedFolder"
              @changeSelectedFolder="changeTemplateFolder"
            />
            <div class="flex-grow-1">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th>
                        <span v-if="folders[selectedFolder]">{{ folders[selectedFolder].name }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="templates && templates.length">
                    <tr
                      v-for="(item, index) in templates"
                      :key="index"
                      class="folder-item"
                    >
                      <td class="d-flex w-100">
                        <div class="item-name">{{ item.name }}</div>
                        <div
                          class="btn btn-info btn-sm text-nowrap ml-auto"
                          data-dismiss="modal"
                          @click="selectTemplate(item)"
                        >
                          送信
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="text-center mt-5">
                        <b>登録テンプレートはありません。</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-center mt-3 text-center">
                <b-pagination
                    v-if="totalRows > perPage"
                    v-model="queryParams.page"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="loadPage"
                    aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div v-else class="my-5 text-center font-weight-bold">登録したテンプレートはありません。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
export default {
  data() {
    return {
      selectedFolder: 0,
      isPc: true,
      queryParams: {}
    };
  },

  computed: {
    ...mapState('template', {
      folders: state => state.folders,
      templates: state => state.templates,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolder];
    },
  },

  async beforeMount() {
    await this.getFolders();
    this.changeTemplateFolder(0);
  },

  methods: {
    ...mapMutations('template', ['setQueryParams']),
    ...mapActions('template', ['getTemplates', 'getFolders']),

    backToFolder() {
      this.isPc = false;
    },

    selectTemplate(template) {
      // eslint-disable-next-line no-undef
      const data = _.cloneDeep(template);
      this.$emit('sendTemplate', data);
    },

    changeTemplateFolder(index) {
      this.selectedFolder = index;
      this.queryParams.page = 1;
      this.isPc = true;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        await this.getTemplates();
      });
    }
  }
};
</script>
<style style="scss" scoped>
  .item-name {
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
