import FolderAPI from '../api/folder_api';
import StreamRouteApi from '../api/stream_route_api';
import { convertKeysToSnakeCase } from '../../core/convertKeys';

export const state = {
  friendList: [],
  foldersIncludeStreamRoutes: [],
  totalRows: 0,
  perPage: 0,
  curPage: 1,
  streamRoutes: [],
  folders: [],
  queryParams: {
    page: 1,
    pageIndex: 1,
    streamRouteId: null,
    folder_id_eq: ''
  }
};

export const mutations = {
  setFriendList(state, friends) {
    state.friendList = friends;
  },

  setFoldersIncludeStreamRoutes(state, foldersIncludeStreamRoutes) {
    state.foldersIncludeStreamRoutes = foldersIncludeStreamRoutes;
  },

  pushFolder(state, folder) {
    state.foldersIncludeStreamRoutes.push(folder);
  },

  updateFolder(state, newItem) {
    const item = state.foldersIncludeStreamRoutes.find(item => item.id === newItem.id);
    if (item) {
      item.name = newItem.name;
    }
  },

  deleteFolder(state, id) {
    const index = state.foldersIncludeStreamRoutes.findIndex(_ => _.id === id);
    state.foldersIncludeStreamRoutes.splice(index, 1);
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },
  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setQueryParam(state, param) {
    Object.assign(state.queryParams, param);
  },

  setStreamRoutes(state, streamRoutes) {
    state.streamRoutes = streamRoutes;
  },

  updateFolderOrder(state, { oldIndex, newIndex }) {
    const movedFolder = state.foldersIncludeStreamRoutes.splice(oldIndex, 1)[0];
    state.foldersIncludeStreamRoutes.splice(newIndex, 0, movedFolder);
  },

  updateStreamRouteOrder(state, { oldIndex, newIndex }) {
    const movedStreamRoute = state.streamRoutes.splice(oldIndex, 1)[0];
    state.streamRoutes.splice(newIndex, 0, movedStreamRoute);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async createStreamRoute(_, query) {
    try {
      return await StreamRouteApi.create(query);
    } catch (error) {
      return error;
    }
  },

  async updateStreamRoute(_, query) {
    try {
      return await StreamRouteApi.update(query);
    } catch (error) {
      return error;
    }
  },

  async deleteStreamRoute(context, id) {
    try {
      return await StreamRouteApi.delete(id);
    } catch (error) {
      return null;
    }
  },

  async copyStreamRoute(context, id) {
    try {
      return await StreamRouteApi.copy(id);
    } catch (error) {
      return null;
    }
  },

  async getStreamRouteDetail(context) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const response = await StreamRouteApi.getDetail(params);
      context.commit('setFriendList', response.data);
      context.commit('setMeta', response.meta);
    } catch (error) {
      console.log(error);
    }
  },

  async getStreamRoutes(context) {
    try {
      const params = {
        page: state.queryParams.pageIndex,
        q: _.omit(state.queryParams, 'page')
      };
      const res = await StreamRouteApi.getStreamRoutes(params);

      context.commit('setStreamRoutes', res.data);
      context.commit('setMeta', res.meta);
    } catch (error) {
      console.log(error);
    }
  },

  async createFolder(context, payload) {
    try {
      const folder = await FolderAPI.create(payload);
      context.commit('pushFolder', folder);
      return folder;
    } catch (error) {
      return null;
    }
  },

  async updateFolder(context, payload) {
    try {
      const response = await FolderAPI.update(payload);
      context.commit('updateFolder', response);
      return response;
    } catch (error) {
      return null;
    }
  },

  async deleteFolder(context, id) {
    try {
      const response = await FolderAPI.delete(id);
      context.commit('deleteFolder', id);
      return response;
    } catch (error) {
      return null;
    }
  },

  async getFolders(context) {
    try {
      const params = { type: 'stream_route' };
      const response = await FolderAPI.list(params);
      context.commit('setFoldersIncludeStreamRoutes', response);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async reorderFolders(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await FolderAPI.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to swap folder positions:', error);
    }
  },

  async reorderStreamRoutes(context, payload) {
    try {
      const convertedPayload = convertKeysToSnakeCase(payload);
      await StreamRouteApi.swapPositions(convertedPayload);
    } catch (error) {
      console.error('Failed to reorder StreamRoutes:', error);
    }
  }
};
